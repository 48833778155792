import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { routes } from '@generouted/react-router/lazy';
import { ErrorBoundary } from '@highlight-run/react';
import { H } from 'highlight.run';
import { StrictMode, useEffect } from 'react';
import { Provider as BusProvider } from 'react-bus';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import './main.scss';

const httpLink = new HttpLink({
  uri: import.meta.env.PROD
    ? '/graphql'
    : `${import.meta.env.VITE_API_URL}/graphql`,
  credentials: 'include',
});
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      if (!import.meta.env.PROD) {
        console.log(
          `[GraphQL Error] :: Message: ${
            err.message
          }, Location: ${JSON.stringify(err.locations)}, Path: ${err.path?.join(
            '.',
          )}`,
        );
      }
    });

    const noAuth = graphQLErrors.some(
      (err) =>
        err.extensions?.['code'] === 'AUTH_NOT_AUTHENTICATED' ||
        err.extensions?.['code'] === 'AUTH_NOT_AUTHORIZED',
    );
    const login = import.meta.env.VITE_LOGIN_URL;
    if (noAuth && login) {
      // make user login and return
      const url = new URL(login);
      url.searchParams.set('returnUrl', window.location.href);
      window.location.replace(url.toString());
    }
  }

  if (networkError) {
    if (!import.meta.env.PROD) {
      console.log('== NETWORK ERROR ==========');
      console.log('[Operation] ::', operation);
      console.log('[Network Error] ::', networkError);
      console.log('===========================');
    }
  }
});
const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: !import.meta.env.PROD,
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network' },
  },
});

const allRoutes: RouteObject[] = [
  {
    children: routes,
    path: '/',
    ErrorBoundary() {
      const error = useRouteError() as Error | undefined;

      useEffect(() => {
        if (!error) {
          return;
        }

        H.consumeError(error);

        if (
          error.message
            .toLowerCase()
            .includes('failed to fetch dynamically imported module')
        ) {
          // if a new version of the app is released, old files might no longer be available
          // reloading allows loading new files
          // https://mitchgavan.com/code-splitting-react-safely/
          // https://dimaip.github.io/2020/04/25/deploying-apps-with-code-splitting/
          window.location.reload();
        }
      }, [error]);

      return null;
    },
  },
];

const router = createBrowserRouter(allRoutes);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <StrictMode>
      <BusProvider>
        <ApolloProvider client={client}>
          <RouterProvider router={router} />
        </ApolloProvider>
      </BusProvider>
    </StrictMode>
  </ErrorBoundary>,
);
